import Link from 'next/link'
import React from 'react'

import { FollowButtonIcon } from '@common/FollowButton'
import { useTheme } from '@context/ThemeContext'
import UrlHelper from '@lib/UrlHelper'
import { useToggleNodeFollow } from '@pages/node/hooks/useToggleNodeFollow'
import nodeApi from '@redux/api/nodeApi'

export const NodePillLoading = () => <span className="mb-1.5 mr-1.5 h-9 w-24 animate-pulse rounded-lg bg-contrast/10" />

const NodePill = ({ slug }: { slug: string }) => {
  const { data: node, isLoading } = nodeApi.useGetNodeDetailsQuery({ slug })
  const handleFollow = useToggleNodeFollow(node)
  const { resolvedTheme } = useTheme()

  const isDarkMode = resolvedTheme === 'dark'
  const followingClassNames = isDarkMode ? 'bg-orange-900 text-orange-100' : 'bg-orange-200 text-orange-800'
  const notFollowingClassNames = isDarkMode ? 'bg-contrast/10 text-gray-300' : 'bg-contrast/10 text-gray-700'

  if (isLoading || !node) return <NodePillLoading />
  return (
    <Link href={UrlHelper.nodePath(slug)} className="btn btn-link mb-1.5 mr-1.5 px-0">
      <div
        className={[
          'flex shrink-0 items-center space-x-1 rounded-lg px-3 py-2 text-sm font-medium transition-colors duration-200',
          node.isFollowing ? followingClassNames : notFollowingClassNames,
        ].join(' ')}
      >
        <span className="opacity-50">#</span>
        <div className="flex space-x-2">
          <span>{node.name}</span>
          <div
            className="-m-1 flex size-4 cursor-pointer self-center hover:scale-105 hover:opacity-80"
            onClick={event => event.preventDefault()}
          >
            <FollowButtonIcon
              isFollowing={node.isFollowing ?? false}
              onFollow={handleFollow}
              deeplinkUrl={UrlHelper.nodePermalink(node.slug)}
              className={
                isDarkMode
                  ? node.isFollowing
                    ? 'text-orange-200'
                    : 'text-gray-400'
                  : node.isFollowing
                    ? 'text-accent'
                    : 'text-gray-500'
              }
            />
          </div>
        </div>
      </div>
    </Link>
  )
}

export default NodePill
