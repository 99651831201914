import { AnimatePresence, motion } from 'framer-motion'
import React, { ReactElement, useEffect, useRef } from 'react'

import useFeedNavigation, { useWheelHandler } from '@hooks/useFeedNavigation'
import IconArrowsIn from '@icons/IconArrowsIn.svg'
import IconArrowsOut from '@icons/IconArrowsOut.svg'
import { IntoUrl } from '@models/IntoUrl'
import { LoggedOutHero } from '@pages/home/components/LoggedOutHero'
import { UrlRateDownButton, UrlRateUpButton } from '@pages/url/components/UrlActions'
import { UrlShareActions } from '@pages/url/components/UrlShareActions'
import { UrlSidePanel } from '@pages/url/components/UrlSidePanel'
import { CurrentUrlContextProvider, useCurrentUrlContext } from '@pages/url/CurrentUrlContext'
import { useAppSelector } from '@redux/store/store'
import { NSFWPromptModal } from '../NSFWPromptModal'

export type NodeStreamPageContextValues =
  | 'likes'
  | 'posts'
  | 'saved'
  | 'home.loggedOut'
  | 'home.loggedIn'
  | 'following'
  | 'topics'

export type NodeStreamPageContext = {
  value: NodeStreamPageContextValues
} | null

interface FullScreenFeedLayoutProps {
  children: ReactElement
  currentUrl: IntoUrl | undefined
}

const FullScreenSideBar = () => {
  const { isFullScreen, setIsFullScreen, currentUrl } = useCurrentUrlContext()

  if (!currentUrl) return <></>
  return (
    <div className="absolute right-0 top-0 z-20 flex h-full flex-col items-center justify-between p-4">
      <motion.button
        onClick={() => setIsFullScreen(!isFullScreen)}
        className="focus:outline-none"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        {isFullScreen ? (
          <IconArrowsIn className="size-4 fill-contrast" />
        ) : (
          <IconArrowsOut className="size-4 fill-contrast" />
        )}
      </motion.button>
      <AnimatePresence>
        {isFullScreen && (
          <motion.div
            className="space-y-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.3 }}
          >
            <UrlShareActions url={currentUrl} buttonClassName="bg-[#0C0C0C]/30 opacity-75" />
            <div className="space-y-1.5">
              <motion.div className="rounded-full bg-primary/90" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                <UrlRateUpButton urlId={currentUrl.url_id} buttonClassName="size-10 !opacity-100" />
              </motion.div>
              <motion.div className="rounded-full bg-primary/90" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                <UrlRateDownButton urlId={currentUrl.url_id} buttonClassName="size-10 !opacity-100" />
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <div className="size-4" />
    </div>
  )
}

const ExpandableWrapper = ({ children }: { children: ReactElement }) => {
  const { enableFullScreenMode } = useAppSelector(state => state.tweaks)
  const { isFullScreen, setIsFullScreen } = useCurrentUrlContext()

  useEffect(() => {
    if (!enableFullScreenMode) return
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isFullScreen) {
        setIsFullScreen(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isFullScreen, setIsFullScreen, enableFullScreenMode])

  if (!enableFullScreenMode) return children
  return (
    <AnimatePresence mode="wait">
      <motion.div
        className={`${isFullScreen ? 'fixed inset-0 z-50' : 'relative'} size-full bg-primary`}
        layout
        initial={false}
        animate={isFullScreen ? { opacity: 1, scale: 1 } : { opacity: 1, scale: 1 }}
        exit={isFullScreen ? { opacity: 1, scale: 1 } : undefined}
        transition={{ duration: 0.3 }}
      >
        {children}
        <FullScreenSideBar />
      </motion.div>
    </AnimatePresence>
  )
}

export const FullScreenFeedLayout: React.FC<FullScreenFeedLayoutProps> = ({
  children,
  currentUrl,
}: FullScreenFeedLayoutProps) => {
  const feedRef = useRef<HTMLDivElement>(null)
  useWheelHandler(feedRef)
  const { isLoginPromptOpen } = useAppSelector(state => state.loginModal)
  const { handleNextNavigation } = useFeedNavigation()
  const { isMuted, isAutoMuted } = useAppSelector(state => state.video)
  return (
    <CurrentUrlContextProvider currentUrl={currentUrl}>
      <div
        className={`relative flex min-h-0 flex-1 flex-col space-y-6 focus:outline-none md:flex-row md:space-y-0`}
        tabIndex={0}
      >
        <div ref={feedRef} className={`size-full`}>
          <ExpandableWrapper>{children}</ExpandableWrapper>
        </div>
        {currentUrl ? (
          <div className="h-full shrink-0 md:sticky md:top-0 md:w-96">
            <div className="relative bottom-0 z-10 flex size-full flex-col md:pt-10">
              <div className="overflow-y-scroll scrollbar-hide">
                <UrlSidePanel key={currentUrl.url_id} url={currentUrl} />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className={`absolute left-0 top-0 size-full ${isLoginPromptOpen ? '' : 'hidden'}`}>
          <LoggedOutHero
            promptText="Continue Exploring"
            isMuted={isMuted || isAutoMuted || !isLoginPromptOpen}
            onContinueClick={handleNextNavigation}
          />
        </div>

        <NSFWPromptModal />
      </div>
    </CurrentUrlContextProvider>
  )
}
